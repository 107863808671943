<template>
	<div class="root">
		<el-card>
			<h3>基础信息</h3>
			<el-divider></el-divider>
			<el-form
				ref="form"
				:model="form"
				label-position="right"
				label-width="110px"
			>
				<el-form-item label="姓名：">
					{{ form.studentName }}
				</el-form-item>
				<el-form-item label="手机号：">
					{{ form.userTele }}
				</el-form-item>
				<el-form-item label="性别：">
					<span v-if="form.sex == 0">女</span>
					<span v-if="form.sex == 1">男</span>
				</el-form-item>
				<el-form-item label="学校：">
					{{ form.deptName }}
				</el-form-item>
				<el-form-item label="年级：">
					{{ form.gradeName }}
				</el-form-item>
				<el-form-item label="身份证号：">
					{{ form.idCard }}
				</el-form-item>
			</el-form>
			<el-button
				class="submit"
				type="danger"
				size="small"
				@click="updateButton"
				>更新信息</el-button
			>
		</el-card>
		<el-dialog
			width="30%"
			title="更新个人信息"
			:visible.sync="dialogVisible"
		>
			<el-form
				ref="submit"
				:model="form1"
				:rules="rules"
				label-position="right"
				label-width="110px"
			>
				<el-form-item label="身份证号：" prop="idNum">
					<el-input
						v-model="form1.idNum"
						placeholder="请输入内容"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submit('submit')"
					>确 定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { getStudentInfo_api, updateStudentInfo_api } from '@/api/user.js'
export default {
	data() {
		//验证身份证号码
		const validateidCard = (rule, value, callback) => {
			if (
				/(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0[1-9]|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/.test(
					value
				) == false
			) {
				callback(new Error('请输入正确的身份证号码'))
			} else {
				callback()
			}
		}
		return {
			form: {},
			dialogVisible: false, //更新对话框
			studentId: this.$store.state.userInfo.docId,
			form1: {
				idNum: '',
			},
			rules: {
				idNum: [
					{
						required: true,
						message: '身份证号码不能为空',
						trigger: ['blur', 'change'],
					},
					{ validator: validateidCard, trigger: 'blur' },
				],
			},
		}
	},
	methods: {
		//获取学生信息
		async getStudentInfo(studentId) {
			let { code, data, msg } = await getStudentInfo_api({
				studentId,
			})
			if (code == 200) {
				this.form = data
				// console.log(this.form)
			} else {
				console.log(msg)
			}
		},
		updateButton() {
			this.dialogVisible = true
			this.form1.idNum = this.form.idCard
		},
		//更新学生信息
		submit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.submitInfo()
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		async submitInfo() {
			let info = { studentId: this.form.id, idCard: this.form1.idNum }
			let { code, data, msg } = await updateStudentInfo_api(info)
			if (code == 200) {
				this.getStudentInfo(this.studentId)
				this.dialogVisible = false
				this.$message({
					type: 'success',
					message: msg,
				})
			} else {
				this.$message({
					type: 'error',
					message: msg,
				})
			}
		},
	},
	mounted() {
		this.getStudentInfo(this.$store.state.userInfo.docId)
	},
}
</script>

<style lang="less" scoped>
.root {
	.el-card {
		margin-bottom: 20px;
		h3 {
			font-weight: 700;
		}
		.el-form {
			margin-left: 25%;
			width: 50%;
			display: grid;
			grid-template-columns: repeat(2, 50%);
			.el-form-item {
			}
		}
		.submit {
			display: block;
			margin: 0 auto;
		}
	}
}
</style>
